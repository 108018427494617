<template>
  <div class="skills-list">
    <el-table :data="tableData" v-loading="loading" style="width: 100%;">
      <el-table-column prop="skills_name" label="姓名" width="120"></el-table-column>
      <el-table-column prop="skills_phone" label="手机号" width="160"></el-table-column>
      <el-table-column prop="chain_name" label="学校" show-overflow-tooltip></el-table-column>
      <el-table-column prop="skills_type_value" label="报考技能" show-overflow-tooltip></el-table-column>
      <el-table-column prop="is_enable_data" label="是否报名" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" :disabled="scope.row.is_enable === 1" @click="updateSkillsFun(scope.row)">{{ scope.row.is_enable === 0 ? '未' : '已' }}报名</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getSkillsAll, updateSkills } from '@/request/api'

export default {
  data() {
    return {
      tableData: [],
      loading: false
    }
  },
  methods: {
    async updateSkillsFun(row) {
      try {
        const res = await updateSkills({
            shop_user_skills_id: row.shop_user_skills_id
        })
        if (res.status !== 200 || res.data.code !== "1000") return this.$message.error(res.data.msg);

        this.init()
        this.$message({ type: "success", message: "修改成功！" });
      } catch(error) {
        this.$message.error('请求发生错误：' + error.message);
      }
    
    },
    async init() {
      if (this.loading) return

      try {
        this.loading = true
        const res = await getSkillsAll()
        this.loading = false
        if (res.status !== 200 || res.data.code !== "1000") return this.$message.error(res.data.msg);

        this.tableData = res.data.data.map(ele => ({
            ...ele,
            is_enable_data: ele.is_enable === 0 ? '未报名' : '已报名'
        }))
      } catch(error) {
        this.loading = false
        this.$message.error('请求发生错误：' + error.message);
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.skills-list {
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
</style>